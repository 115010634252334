import axios from 'axios';
import router from '../router';
import { parseToCamel } from '@/helpers/transformCase';
import i18n from '@/i18n';

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const loginInterceptor = (config) => {
  config.headers['Accept-Language'] = i18n.global.locale.value;
  config.headers['X-CSRFToken'] = getCookie('csrftoken');
  return config;
};

const userApi = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
  withCredentials: true,
});

const userApiUnprotected = axios.create({
  baseURL: `${import.meta.env.VITE_UNPROTECTED_API_URL}`,
  withCredentials: true,
});

userApi.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401 || !router.app._route.meta.requiresAuth) {
      return Promise.reject(parseToCamel(error));
    }

    router.push({ name: 'login' });
  }
);

userApiUnprotected.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401 || !router.app._route.meta.requiresAuth) {
      return Promise.reject(parseToCamel(error));
    }

    router.push({ name: 'login' });
  }
);

userApi.interceptors.request.use(loginInterceptor);
userApiUnprotected.interceptors.request.use(loginInterceptor);

export { userApi, userApiUnprotected };
